<script setup lang="ts">
/**
 * @file This component comes as a pair with the global_input_dialog Pinia store.
 */
import { defineAsyncComponent } from '@@/bits/vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzPlainButton, { OzPlainButtonSizePreset } from '@@/library/v4/components/OzPlainButton.vue'
import { useGlobalInputDialogStore } from '@@/pinia/global_input_dialog'
import { useVirtualKeyboardHeight } from '@@/vuecomposables/virtual_keyboard_height'
import { storeToRefs } from 'pinia'
import { nextTick, ref, watch } from 'vue'

const OzOverlay = defineAsyncComponent(() => import('@@/library/v4/components/OzOverlay.vue'))
const OzInputDialogBox = defineAsyncComponent(() => import('@@/library/v4/components/OzInputDialogBox.vue'))

const globalInputDialogStore = useGlobalInputDialogStore()
const { virtualKeyboardHeight } = useVirtualKeyboardHeight()

const { isOpen, inputDialogData } = storeToRefs(globalInputDialogStore)
const { updateInputValue, closeInputDialog, submitInputDialog } = globalInputDialogStore

const dialogBox = ref<InstanceType<typeof OzInputDialogBox>>()

watch(
  isOpen,
  (newIsOpen: boolean): void => {
    if (newIsOpen) nextTick(() => dialogBox.value?.focus())
  },
  { immediate: true },
)

const executeHeaderTopEndContentActions = (): void => {
  if (
    inputDialogData.value.headerTopEndContent != null &&
    Array.isArray(inputDialogData.value.headerTopEndContent.actions)
  ) {
    const { actions } = inputDialogData.value.headerTopEndContent
    actions.forEach((action) => {
      if (typeof action === 'function') action()
    })
  }
}
</script>

<template>
  <OzOverlay
    v-if="isOpen"
    data-testid="globalInputDialog"
    class="flex justify-center items-center"
    :style="{
      paddingBottom: `${virtualKeyboardHeight}px`,
    }"
    :should-fade-in="inputDialogData.shouldFadeIn"
    @scrim-click="closeInputDialog"
    @scrim-esc="closeInputDialog"
  >
    <OzInputDialogBox
      ref="dialogBox"
      :icon-src="inputDialogData.iconSrc"
      :icon-alt="inputDialogData.iconAlt"
      :title="inputDialogData.title"
      :subtitle="inputDialogData.subtitle"
      :label="inputDialogData.inputLabel"
      :value="inputDialogData.inputValue"
      :valid="inputDialogData.isValid"
      :validation-message="inputDialogData.validationMessage"
      :type="inputDialogData.inputType"
      :aria-label="inputDialogData.inputAriaLabel"
      :placeholder="inputDialogData.inputPlaceholder"
      :required="inputDialogData.inputRequired"
      :max-length="inputDialogData.inputMaxLength"
      :cancel-button-text="inputDialogData.cancelButtonText"
      :submit-button-text="inputDialogData.submitButtonText"
      :submit-button-disabled="inputDialogData.submitButtonDisabled"
      @update="updateInputValue"
      @cancel="closeInputDialog"
      @submit="submitInputDialog"
    >
      <template v-if="inputDialogData.headerTopEndContent" #headerTopEndContent>
        <div>
          <OzPlainButton
            :title="inputDialogData.headerTopEndContent.title"
            :aria-label="inputDialogData.headerTopEndContent.ariaLabel"
            :size-preset="OzPlainButtonSizePreset.Bare"
            @click="executeHeaderTopEndContentActions"
          >
            <OzIcon
              :size="24"
              :name="inputDialogData.headerTopEndContent.iconName"
              :label="inputDialogData.headerTopEndContent.iconAlt"
            />
          </OzPlainButton>
        </div>
      </template>
    </OzInputDialogBox>
  </OzOverlay>
</template>
