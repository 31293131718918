// @file Composable that returns the virtual keyboard height (roughly accurate)
import { useWindowSizeStore } from '@@/pinia/window_size'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export const useVirtualKeyboardHeight = (): {
  isVirtualKeyboardShowing: typeof isVirtualKeyboardShowing
  virtualKeyboardHeight: typeof virtualKeyboardHeight
} => {
  const { windowHeight, visualViewportHeight, visualViewportScale } = storeToRefs(useWindowSizeStore())

  const actualVisualViewportHeight = computed(() => Math.round(visualViewportHeight.value * visualViewportScale.value))
  const isVirtualKeyboardShowing = computed(() => actualVisualViewportHeight.value < windowHeight.value)
  const virtualKeyboardHeight = computed(() =>
    isVirtualKeyboardShowing.value ? windowHeight.value - actualVisualViewportHeight.value : 0,
  )

  return {
    isVirtualKeyboardShowing,
    virtualKeyboardHeight,
  }
}
